module.exports = {
	'sportstoday.click': {
		title: 'Sports Today',
		icon: '/icon/1.png',
		IAmTitle: 'Sports Today',
		IAmURL: 'sportstoday.click',
		IAMEMAIL: 'sportstoday.support@gmail.com',
		iamkeywords: 'SportsToday',
		description: 'Your Ultimate Guide to Sports Knowledge',
		type: 'SportsEventsWiki',
		ABOUTUS: 'Stay updated with the latest sports news, live scores, and expert analysis. Connect with fans and celebrate athletic excellence.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:d55e8cb98079d9ee21a66d',
			measurementId: 'G-Q9RWC6BN15',
		},
	},
	'buildingstoday.one': {
		title: 'Buildings Today',
		icon: '/icon/2.png',
		IAmTitle: 'Buildings Today',
		IAmURL: 'buildingstoday.one',
		IAMEMAIL: 'buildingstoday.support@gmail.com',
		iamkeywords: 'BuildingsToday',
		description: 'Discover the Art of Architecture',
		type: 'TallestBuildings',
		ABOUTUS: 'Explore architectural marvels and construction trends. Discover innovative designs and connect with enthusiasts in the building community.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:1542de3d834d68da21a66d',
			measurementId: 'G-JB4K6TL4YE',
		},
	},
	'festivalstoday.org': {
		title: 'Festivals Today',
		icon: '/icon/3.png',
		IAmTitle: 'Festivals Today',
		IAmURL: 'festivalstoday.org',
		IAMEMAIL: 'festivalstoday.support@gmail.com',
		iamkeywords: 'FestivalsToday',
		description: 'Celebrate Cultures Around the World',
		type: 'GlobalFestivalsGuide',
		ABOUTUS: 'Celebrate global festivals and cultural events. Discover traditions, connect with communities, and enjoy vibrant celebrations worldwide.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:9cbe408d7ba9960721a66d',
			measurementId: 'G-G2EFKWHFSY',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, 6466344935166719',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1648293084',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2103760098',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3592764292',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8160794760',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8222274444',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8477596759',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7164515082',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'todayswonders.one': {
		title: "Today's Wonders",
		icon: '/icon/4.png',
		IAmTitle: "Today's Wonders",
		IAmURL: 'todayswonders.one',
		IAMEMAIL: 'todayswonders.support@gmail.com',
		iamkeywords: "Today'sWonders",
		description: 'Explore the Marvels of Our World',
		type: 'WondersoftheWorld',
		ABOUTUS: 'Uncover the wonders of the world. Explore breathtaking sites, connect with travelers, and share awe-inspiring experiences every day.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:e8d55f366aa9037b21a66d',
			measurementId: 'G-S47TSPNTQT',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, 6466344935166719',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5851433416',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8968551345',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5534631425',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2455113882',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8828950549',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6202787209',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1090061322',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'heritageguidetoday.click': {
		title: 'Heritage Guide Today',
		icon: '/icon/5.png',
		IAmTitle: 'Heritage Guide Today',
		IAmURL: 'heritageguidetoday.click',
		IAMEMAIL: 'heritageguidetoday.support@gmail.com',
		iamkeywords: 'HeritageGuideToday',
		description: 'Uncover the Stories of Our Past',
		type: 'GlobalHeritageGuide',
		ABOUTUS: 'Discover cultural heritage and historical sites. Connect with history enthusiasts and explore the stories that shaped our world.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:b9957e11e856caaf21a66d',
			measurementId: 'G-QJ79R9P1M8',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, 6466344935166719',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4889705536',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7969223076',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8461947595',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8030702753',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2211571302',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8585407961',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3209620914',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'nationalparks.today': {
		title: 'National Parks Today',
		icon: '/icon/6.png',
		IAmTitle: 'National Parks Today',
		IAmURL: 'nationalparks.today',
		IAMEMAIL: 'nationalparks.support@gmail.com',
		iamkeywords: 'NationalParksToday',
		description: "Experience Nature's Majesty",
		type: 'NationalParksExplorer',
		ABOUTUS: 'Explore stunning national parks and natural wonders. Connect with nature lovers and enjoy outdoor adventures across the globe.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:14a124e80ce5dcb021a66d',
			measurementId: 'G-VYT0VS1DG0',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, 6466344935166719',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8270375904',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4454591264',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8960641040',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7661267164',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6334477707',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8078804219',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3892147195',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'adventuresports.today': {
		title: 'Adventure Sports Today',
		icon: '/icon/7.png',
		IAmTitle: 'Adventure Sports Today',
		IAmURL: 'adventuresports.today',
		IAMEMAIL: 'adventuresports.support@gmail.com',
		iamkeywords: 'AdventureSportsToday',
		description: 'Thrill-Seeking Adventures Await',
		type: 'AdventureSportsWiki',
		ABOUTUS: 'Dive into the world of adventure sports. Discover thrilling activities, connect with enthusiasts, and embrace your adventurous spirit.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:2d1962b7f156312121a66d',
			measurementId: 'G-09PF3QRJBN',
		},
	},
	'ethnicgroup.today': {
		title: 'Ethnic Group Today',
		icon: '/icon/8.png',
		IAmTitle: 'Ethnic Group Today',
		IAmURL: 'ethnicgroup.today',
		IAMEMAIL: 'ethnicgroup.support@gmail.com',
		iamkeywords: 'EthnicGroupToday',
		description: 'Embrace Diversity and Unity',
		type: 'EthnicGroupsEncyclopedia',
		ABOUTUS: 'Explore diverse ethnic groups and cultures. Connect with communities, celebrate diversity, and learn about rich cultural traditions.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:6291ecdd626d02b721a66d',
			measurementId: 'G-S5H2HKSGTF',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, 6466344935166719',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7469695479',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4314990462',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7455987687',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2999967429',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3516742670',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7264415993',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4638252651',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'michelinguide.today': {
		title: 'Michelin Guide Today',
		icon: '/icon/9.png',
		IAmTitle: 'Michelin Guide Today',
		IAmURL: 'michelinguide.today',
		IAMEMAIL: 'michelinguide.support@gmail.com',
		iamkeywords: 'MichelinGuideToday',
		description: 'Savor Culinary Excellence',
		type: 'MichelinGuide',
		ABOUTUS: 'Discover cultural heritage and historical sites. Connect with history enthusiasts and explore the stories that shaped our world.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:21f7d76f4494189021a66d',
			measurementId: 'G-LZ9DHV5WQ1',
		},
	},
	'ballsportevent.today': {
		title: 'Ball Sport Event Today',
		icon: '/icon/10.png',
		IAmTitle: 'Ball Sport Event Today',
		IAmURL: 'ballsportevent.today',
		IAMEMAIL: 'ballsportevent.support@gmail.com',
		iamkeywords: 'BallSportEventToday',
		description: 'Discover the World of Ball Sports',
		type: 'BallSportsWiki',
		ABOUTUS: 'Stay updated on ball sports events worldwide. Connect with fans, follow your favorite teams, and celebrate sporting achievements.',
		firebase: {
			apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
			authDomain: 'webs-3411d.firebaseapp.com',
			projectId: 'webs-3411d',
			storageBucket: 'webs-3411d.firebasestorage.app',
			messagingSenderId: '657973516568',
			appId: '1:657973516568:web:2397aedf8b8499fc21a66d',
			measurementId: 'G-WBCF749PRS',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, 6466344935166719',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5759762635',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8899161368',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4123418774',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2810337101',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9507435953',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5568190943',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2942027604',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'michelinguide.click': {
		title: 'Michelin Guide Today',
		icon: '/icon/9.png',
		IAmTitle: 'Michelin Guide Click',
		IAmURL: 'michelinguide.click',
		IAMEMAIL: 'michelinguide.support@gmail.com',
		iamkeywords: 'MichelinGuideClick',
		description: 'Savor Culinary Excellence',
		type: 'MichelinGuide',
		ABOUTUS: 'Discover the best culinary experiences with expert restaurant recommendations, reviews, and dining guides. Your go-to source for unforgettable meals and top-tier dining experiences worldwide.',
		firebase: {
			apiKey: 'AIzaSyCHu4Quq9SMB6lFMekleHi9pJYJWoQDEbQ',
			authDomain: 'webs-9476a.firebaseapp.com',
			projectId: 'webs-9476a',
			storageBucket: 'webs-9476a.firebasestorage.app',
			messagingSenderId: '96762571859',
			appId: '1:96762571859:web:12c3019b10d4fab958aaa6',
			measurementId: 'G-KXWNHK42DH',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9752624750',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8439543082',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9221479007',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7126461415',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3187216401',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4663949601',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2037786260',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'tallestbuildings.today': {
		title: 'Tallest Buildings',
		icon: '/icon/2.png',
		IAmTitle: 'Tallest Buildings',
		IAmURL: 'tallestbuildings.today',
		IAMEMAIL: 'tallestbuildings.support@gmail.com',
		iamkeywords: 'TallestBuildings',
		description: 'Discover the Art of Architecture',
		type: 'TallestBuildings',
		ABOUTUS: 'Discover the world’s tallest architectural marvels with up-to-date insights on the most impressive skyscrapers. Stay informed about the design, history, and innovation behind these towering structures.',
		firebase: {
			apiKey: 'AIzaSyCHu4Quq9SMB6lFMekleHi9pJYJWoQDEbQ',
			authDomain: 'webs-9476a.firebaseapp.com',
			projectId: 'webs-9476a',
			storageBucket: 'webs-9476a.firebasestorage.app',
			messagingSenderId: '96762571859',
			appId: '1:96762571859:web:0b8427526b680fd858aaa6',
			measurementId: 'G-8LR7VBE4L0',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8247971395',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7668724181',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3969152320',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2656070651',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9724704598',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1342988989',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8411622920',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'adventuresports.click': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		IAmTitle: 'Adventure Sports Click',
		IAmURL: 'adventuresports.click',
		IAMEMAIL: 'adventuresports.support@gmail.com',
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'AdventureSportsWiki',
		ABOUTUS: 'We bring you the latest in adventure sports, offering expert tips, gear reviews, and exciting destinations. Fuel your passion for adventure and explore new thrills with us.',
		firebase: {
			apiKey: 'AIzaSyCHu4Quq9SMB6lFMekleHi9pJYJWoQDEbQ',
			authDomain: 'webs-9476a.firebaseapp.com',
			projectId: 'webs-9476a',
			storageBucket: 'webs-9476a.firebasestorage.app',
			messagingSenderId: '96762571859',
			appId: '1:96762571859:web:b30ed3b723ec8cef58aaa6',
			measurementId: 'G-E2Z2S75XBF',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1682563046',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9369481372',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7716825646',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8818817032',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5090662303',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5561162527',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2464498967',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'sportswiki.click': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		IAmTitle: 'Sports Wiki',
		IAMEMAIL: 'sportswiki.support@gmail.com',
		IAmURL: 'sportswiki.click',
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'SportsEventsWiki',
		ABOUTUS: 'We are dedicated to providing comprehensive sports information. Our team strives to offer detailed insights, stats, and stories to sports enthusiasts, aiming to enhance your passion and knowledge in the sporting world. ',
		firebase: {
			apiKey: 'AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM',

			authDomain: 'webs-new.firebaseapp.com',

			projectId: 'webs-new',

			storageBucket: 'webs-new.firebasestorage.app',

			messagingSenderId: '374792695097',

			appId: '1:374792695097:web:8697468d7a6575a287cce8',

			measurementId: 'G-ELTZRX5RLY',
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3959044466',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7954393123',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6393636113',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2454391100',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1388984774',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9075903103',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6449739768',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'buildingswiki.info': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": 'Buildings Wiki',
		"IAMEMAIL": "buildingswiki.support@gmail.com",
		"IAmURL": "buildingswiki.info",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'TallestBuildings',
		ABOUTUS: 'We are a platform that offers extensive knowledge on various buildings. Our experts curate detailed information to help you understand the architecture, history, and significance of different structures. ',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:4b0e1b7f7111d67d87cce8",

			measurementId: "G-F6FHCVDQPZ"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3823576426',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5391545632',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4933478116',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4099742742',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6534334397',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2595089382',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9949737745',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'festivalsguide.info': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Festivals Guide",
		"IAMEMAIL": "festivalsguide.support@gmail.com",
		"IAmURL": "festivalsguide.info",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'GlobalFestivalsGuide',
		ABOUTUS: 'A comprehensive platform offering detailed insights into global festivals, traditions, and celebrations, helping users explore diverse cultures and enrich their experiences worldwide.',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:8ed4e5d993cd5a0e87cce8",

			measurementId: "G-7ND00XPLSM"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7323574400',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4697411066',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7132002714',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2860751062',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7464272687',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2198406080',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1205986090',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'wondersoftheworld.info': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Wonders Of The World",
		"IAMEMAIL": "wondersoftheworld.support@gmail.com",
		"IAmURL": "wondersoftheworld.info",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'GlobalFestivalsGuide',
		ABOUTUS: 'We provide fascinating insights into humanity\'s greatest marvels, celebrating architectural, natural, and cultural wonders that inspire awe and curiosity worldwide. Explore and discover the extraordinary.',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:2a1b82dc9095848687cce8",

			measurementId: "G-8WQGG8TX76"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3192757702',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1879676038',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8253512697',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1452300623',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7826137280',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1815230887',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8681151433',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'heritagewiki.info': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Heritage Wiki",
		"IAMEMAIL": "heritagewiki.support@gmail.com",
		"IAmURL": "heritagewiki.info",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'GlobalHeritageGuide',
		ABOUTUS: 'A platform dedicated to preserving cultural heritage, offering resources, articles, and community collaboration to explore and protect global traditions and history.',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:6de91bde9011b18c87cce8",

			measurementId: "G-27KPDK8R02"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6420690306',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3886892270',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8585782669',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9375022671',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6748859331',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5435777663',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9183450983',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'nationalparkwiki.click': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "National Park Wiki",
		"IAMEMAIL": "nationalparkwiki.support@gmail.com",
		"IAmURL": "nationalparkwiki.click",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'NationalParksExplorer',
		ABOUTUS: 'We provide reliable, concise information about national parks worldwide, offering travelers and nature enthusiasts a trusted resource for exploration and discovery.',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:cf12c151818c74b387cce8",

			measurementId: "G-T7YQEE2RVY"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7870369319',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1623659198',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6684414185',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2745169175',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2618042639',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9119005834',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7805924167',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'adventuresportswiki.online': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Adventure Sports Wiki",
		"IAMEMAIL": "adventuresportswiki.support@gmail.com",
		"IAmURL": "adventuresportswiki.online",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'AdventureSportsWiki',
		ABOUTUS: 'We are dedicated to providing enthusiasts with reliable, exciting, and comprehensive information about adventure sports, inspiring exploration and fostering a passionate community.',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:2dfc8df3cd8c788087cce8",

			measurementId: "G-4GXN6ZWSCK"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5179760824',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2553597482',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1240515811',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4929608688',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5052634286',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '6301270800',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8756075579',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'ethnicgroupswiki.online': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Ethnic Groups Wiki",
		"IAMEMAIL": "ethnicgroupswiki.support@gmail.com",
		"IAmURL": "ethnicgroupswiki.online",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'EthnicGroupsEncyclopedia',
		ABOUTUS: 'This platform provides detailed information about various ethnic groups, their cultures, histories, and traditions, fostering understanding and promoting global diversity.',
		firebase: {
			apiKey: "AIzaSyAIKJ15cm-xT7yU4ovQjYW_QhUf_tAqvCM",

			authDomain: "webs-new.firebaseapp.com",

			projectId: "webs-new",

			storageBucket: "webs-new.firebasestorage.app",

			messagingSenderId: "374792695097",

			appId: "1:374792695097:web:4a615744a1fea32587cce8",

			measurementId: "G-JE1QXYT75Y"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2362025798',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2426470942',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7487225934',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4861062591',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '2234899251',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '4816830568',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7295654249',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
	'michelinworldwild.click': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Michelin Worldwild",
		"IAMEMAIL": "michelinworldwild.support@gmail.com",
		"IAmURL": "michelinworldwild.click",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'MichelinGuide',
		ABOUTUS: 'We are dedicated to providing global insights into culinary excellence, guiding food enthusiasts to discover top dining experiences and culinary trends worldwide.',
		firebase: {
			apiKey: "AIzaSyAHE2i3DdDrkuywDi7bh6IAPO-_2A_wbcA",
			authDomain: "webs-89e21.firebaseapp.com",
			projectId: "webs-89e21",
			storageBucket: "webs-89e21.firebasestorage.app",
			messagingSenderId: "1096744027429",
			appId: "1:1096744027429:web:83f30a7efbd0a5339246cc",
			measurementId: "G-54RSN65NW5"
		},
	},
	'ballsportswiki.info': {
		title: 'Adventure Sports Click',
		icon: '/icon/7.png',
		"IAmTitle": "Ball Sports Wiki",
		"IAMEMAIL": "ballsportswiki.support@gmail.com",
		"IAmURL": "ballsportswiki.info",
		iamkeywords: 'AdventureSportsClick',
		description: 'Thrill-Seeking Adventures Await',
		type: 'BallSportsWiki',
		ABOUTUS: 'We are dedicated to providing comprehensive information on a wide range of ball sports. Our goal is to offer in-depth articles, news, and resources to enthusiasts and players alike, fostering a deeper understanding and appreciation for the games.',
		firebase: {
			apiKey: "AIzaSyAHE2i3DdDrkuywDi7bh6IAPO-_2A_wbcA",

			authDomain: "webs-89e21.firebaseapp.com",

			projectId: "webs-89e21",

			storageBucket: "webs-89e21.firebasestorage.app",

			messagingSenderId: "1096744027429",

			appId: "1:1096744027429:web:ee6b76bc491adfc29246cc",

			measurementId: "G-FSSTE49WCH"
		},
		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '8544290766',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad2: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5295342711',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad3: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '7251422210',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad4: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '1851755875',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad5: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '5938340542',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad6: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '3982261047',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
			ad7: {
				style: 'display:block',
				'data-ad-client': 'ca-pub-6466344935166719',
				'data-ad-slot': '9665800743',
				'data-ad-format': 'auto',
				'data-full-width-responsive': 'true',
			},
		},
	},
}
